header {
  width: 100%;
  height: 56px;
  border-bottom: solid 1px ghostwhite;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  z-index: 10;
  backdrop-filter: blur(5px);
}
